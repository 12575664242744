import React from "react"
import {
  useI18next,
} from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CaseStoriesContent from "../components/case-stories-content3"



const CaseStories2Page = ({ location }) => {
  const {t} = useI18next();
  return (
    <Layout>
      <SEO title={t("MENU.CASE-STORIES")} />
      <CaseStoriesContent hash={location.hash} />
    </Layout>
  )
}

export default CaseStories2Page