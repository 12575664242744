import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import {Link, useI18next, I18nextContext} from 'gatsby-plugin-react-i18next';

import scrollToElement from 'scroll-to-element';
import Masonry from 'react-masonry-css'

import Img from "gatsby-image"

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import slugify from 'slugify';

import styled from "styled-components"

import SectionHeader from './section-header';
import Avatar from '@material-ui/core/Avatar';
import bgImageColour from '../images/virtual-brain-in-hands.jpg'


const Container = styled.div`
    width: 100%;
    text-align: center;
    margin: 0 auto;
    margin-top: -131px;
    padding-top: 131px;
    background-color: #20466b;
    background-image: url(${(props)=>props.backgroundImage});
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    @media screen and (max-width: 1220px) {
        margin-top: 0;
        padding-top: 0;
    }
`


const ContentWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    background-color: ${props=>props.$backgroundColour};
    color: ${props=>props.$colour};
    padding-top: 60px;
    padding-bottom: 60px;
    
    @media screen and (max-width: 1220px) {
        padding-top: 90px;
    }
    
    
    @media screen and (max-width: 767px) {
        padding-top: 73px;
    }
`

const ContentInner = styled.div`
    // width: 73.306%;
    margin: 0 auto;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    // flex-grow: 0;
    // flex-shrink: 0;
    // flex-basis: 0;
`

const Content = styled.div`
    margin: 0 auto;
    margin-top: 40px;
    width: 1372px;
    max-width: 90%;

    
    p { 
        
        font-size: 20px;
        line-height: 1.4;
        margin-top: 14px;
        font-weight: 400;
        white-space: pre-wrap;
        margin-bottom: 14px;
        text-align: left;
        
      @media screen and (max-width: 767px) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
`

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(255,255,255,0.9)',
      marginBottom: 30,
    },
    
    summaryRoot: {
      position: 'relative',
      minHeight: 220,
      height: 280,
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      
      

      '& .MuiAccordionSummary-content': {
        margin: 0,
        
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
      },
      
      '&:hover': {
        '& $caseStoryImage': {
          transform: 'scale(1.1)',
          transition: 'transform 1s ease',
        },
      },
      
    },
    caseStoryImageWrapper: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      
      '&::after': {
        position: 'absolute',
        content: "''",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        // background: 'rgba(44, 70, 107, 0.6)',
      },
    },
    caseStoryImage: {
        width: '100%',
        height: '100%',
        transition: 'transform 1s ease',
    },
    heading: {
      '&&': {
        position: 'absolute',
        padding: 10,
        alignSelf: 'center',
        color: 'white',
        fontSize: 26,
        marginBottom: 0,
        // width: 'calc(100% - 48px)',
        // width: 'auto',
        width: '100%',
        bottom: 0,
        background: 'rgba(0,0,0,0.4)',
        // backdropFilter: 'brightness(0.6)',
      },
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: '#2c466b',
      fontWeight: '700',
    },
    
    accordionDetails: {
      '& h3': {
        marginBottom: 32,
        
        ["@media screen and (max-height:767px)"]: {
          marginBottom: 0,
        },
      },

    },

    
    ul: {
      fontSize: '20px',
      lineHeight: '1.4',
      marginTop: '14px',
      fontWeight: '400',
      whiteSpace: 'pre-wrap',
      marginBottom: '14px',
      textAlign: 'left',
      color: '#2c466b',
    },
    radio: {
        position: 'fixed',
        bottom: 0,
        left: 2,
        zIndex: 1000000,
    },
    masonryGrid: {
      display: 'flex',
      marginLeft: '-10px', 
      width: 'auto',
      '& .masonry-grid_column': {
        paddingLeft: 10,
        backgroundClip: 'padding-box',
        
        '& > div': {
          marginBottom: 10,
        }
      }
    
    }
  }),
);


const CaseStoriesContent3 = ({hash}) => {
  
    const context = React.useContext(I18nextContext);
    const {t} = useI18next();
    const language = context.language;
    
    const items = useStaticQuery(graphql`
      query {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(case-stories)/"  }}) {
          edges {
            node {
              frontmatter {
                title
                background {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                    }
                }
                language
              }
              html
              id
            }
          }
        }
      }
    `)
    
    let posts = items.allMarkdownRemark.edges
    let postsFilteredByLanguage = posts.filter(post=>post.node.frontmatter.language == language)
  
    const classes = useStyles();
    //const [expanded, setExpanded] = React.useState(false);
    const [expanded, setExpanded] = React.useState(hash ? hash.substring(1) : false);
    
  
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setTimeout(function(){scrollToElement(`#${panel}`, {offset: -93, duration: 1500})}, 500 )
    };
  
    return (
        
        <Container backgroundImage={bgImageColour}>  
            <ContentWrapper id="mission" >
                <SectionHeader id="section-header" title={t("CASE-STORIES.TITLE")} subtitle={t("CASE-STORIES.SUBTITLE")}  withBackground={false}/>
                <Content>
                    <ContentInner>
                      <Masonry
                        breakpointCols={{
                          default: 2,
                          767: 1,
                        }}
                        className={classes.masonryGrid}
                        columnClassName="masonry-grid_column">
                        
                        {
                          postsFilteredByLanguage.map((post, index)=>{
                          
                            let contentId = slugify(post.node.frontmatter.title, {remove: /[…...?!]/g});
                            contentId = contentId.toLowerCase();
                            
                            return (
                              <Accordion key={contentId} className={classes.root} expanded={expanded === contentId} onChange={handleChange(contentId)}>
                                <AccordionSummary
                                  // expandIcon={<ExpandMoreIcon />}
                                  aria-controls={contentId}
                                  id={contentId}
                                  classes={{root: classes.summaryRoot}}
                                >
                                  <div className={classes.caseStoryImageWrapper}>
                                    <Img 
                                      className={classes.caseStoryImage}
                                      fluid={post.node.frontmatter.background.childImageSharp.fluid}
                                      alt={post.node.frontmatter.title}
                                    />
                                  </div>
                                  <Typography className={classes.heading}>{post.node.frontmatter.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.accordionDetails}>
                                    <article className={classes.ul}>
                                        <div dangerouslySetInnerHTML={{__html: post.node.html}} />
                                    </article>
                                </AccordionDetails>
                              </Accordion>
                            
                            
                            )
                          })
                        }
                       
                        </Masonry>
                    </ContentInner>
                </Content>
            </ContentWrapper>
        </Container>
        
    
    )
}

export default CaseStoriesContent3